:root {
  --app-background-color: #f7f7f7;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--app-background-color);
  overflow-y: auto;
}
.grecaptcha-badge {
  display: none !important;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
}

.react-viewer-mask {
  background-color: transparent !important;
}

.sticky-inner-wrapper {
  width: 100%;
}

.ag-react-container {
  height: 100%;
  display: flex;
  align-items: center;
}

div.ag-root .ag-cell-focus {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media screen and (max-height: 575px) {
  .g-recaptcha-bubble-arrow + div {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}
