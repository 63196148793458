.map-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40vh;
}

.loader {
  font-size: 24px;
}

.map {
  width: 500px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}

.state {
  width: 33.33%;
  height: 33.33%;
  border: 1px solid #000;
}

.ativo {
  background-color: #00ff00;
}

.lento {
  background-color: #ffa500;
}

.inativo {
  background-color: #ff0000;
}

#svg-map text {
  fill: #fff;
  font: 12px Arial-BoldMT, sans-serif;
  cursor: pointer;
}

#svg-map a {
  text-decoration: none;
}

#svg-map a:hover {
  cursor: pointer;
  text-decoration: none;
}

#svg-map a:hover path {
  fill: #aaa !important;
}

#svg-map .circle {
  stroke-width: 1px;
  stroke: white;
}

#svg-map a:hover .circle {
  fill: #aaa !important;
  cursor: pointer;
}
.cardZone {
  display: flex;
  align-items: flex-end;
}
.card {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; /* Adiciona uma borda sólida com cor cinza */
}

.card h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  margin-bottom: 5px;
}

.badge {
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 10px; /* Adiciona espaço no topo de 10 pixels */
  color: white; /* Define a cor do texto como branco */
}

/* Estilos das cores */
.bg-success {
  background-color: #2eca8b;
  /*border: 1px solid #06b871;*/
}

.bg-yellow {
  background-color: #ffc700;
  /*border: 1px solid darkgoldenrod;*/
}

.bg-warning {
  background-color: #f17425;
  /*border: 1px solid darkorange;*/
}

.bg-danger {
  background-color: #e43f52;
  /*border: 1px solid darkred;*/
}
.bg-off {
  background-color: #7d7d7d;
  /*border: 1px solid darkred;*/
}
.mb-3 {
  margin: auto;
  margin-top: 500px;
}
.color-text {
  color: #2eca8b;
  font-weight: bold;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  color: gray;
}
