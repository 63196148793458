:root {
  --app-background-color: #f7f7f7;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--app-background-color);
  overflow-y: auto;
}
.grecaptcha-badge {
  display: none !important;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
}

.react-viewer-mask {
  background-color: transparent !important;
}

.sticky-inner-wrapper {
  width: 100%;
}

.ag-react-container {
  height: 100%;
  display: flex;
  align-items: center;
}

div.ag-root .ag-cell-focus {
  -webkit-user-select: text;
  user-select: text;
}

@media screen and (max-height: 575px) {
  .g-recaptcha-bubble-arrow + div {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}

@media screen and (orientation: portrait) {
  .swal2-container-error,
  .swal2-container-success {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
@media screen and (orientation: landscape) {
  .swal2-container-error,
  .swal2-container-success {
    z-index: 1200;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    height: 60vh;
    border-radius: 4px;
  }
}
.swal2-container-success {
  background-color: #00d318;
  visibility: hidden;
  animation: 4s color-change;
}
.swal2-container-error {
  background-color: #ed145b;
}
.swal2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swal2-status {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  animation: 0.4s anim-lineUp ease-in;
}
.swal2-redirect {
  padding-top: 5vh;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  animation: 0.8s message ease-in;
}
.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  zoom: normal;
  display: flex;
  border-color: white;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: white;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success {
  border-color: white;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: white;
  z-index: 2;
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.875em;
  left: 0.875em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-animate-success-icon .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-animate-success-icon .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-animate-success-icon .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-animate-error-icon {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-animate-error-icon .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes message {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes color-change {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
  75% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.875em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #f58288;
  color: #f58288;
  padding: 4px 12px;
  margin-right: 8px;

  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;
}
.PresetDateRangePicker_button:active {
  outline: 0;
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #f58288;
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput__block {
  display: block;
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_show {
  width: 22px;
  position: absolute;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid transparent;
  border-right: 33px solid #f58288;
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #eb7177;
}
.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #f58288;
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid #eb7177;
}
.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #f58288;
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid #eb7177;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -28px;
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: -28px;
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: -28px;
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff;
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px double #e4e7e7;
  color: inherit;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit;
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}
.CalendarDay__outside:hover {
  border: 0;
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848;
}
.CalendarDay__selected_span {
  background: #e56168;
  border: 1px solid #d0787d;
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #d0787d;
  border: 1px solid #d0787d;
  color: #fff;
}
.CalendarDay__last_in_range {
  border-right: #f58288;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #f58288;
  border: 1px solid #f58288;
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #cc7d81;
  border: 1px solid #d4656a;
  color: #832a2f;
}
.CalendarDay__hovered_span:active {
  background: #d4656a;
  border: 1px solid #d4656a;
  color: #832a2f;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}
.CalendarMonthGrid__vertical {
  margin: 0 auto;
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__horizontal {
  height: 0;
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}
.DayPicker__horizontal {
  background: #fff;
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.DayPicker_transitionContainer__horizontal {
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: transparent;
  position: relative;
  display: inline-block;
  width: 110px;
  vertical-align: middle;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}
.DateInput_input {
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
  background-color: transparent;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  outline: 0;
  background: transparent;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #eb7177;
  border-left: 0;
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}
.DateInput_fangShape {
  fill: #fff;
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}
.DateRangePickerInput__disabled {
  background: #f2f2f2;
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.DateRangePicker {
  position: relative;
  flex-shrink: 0;
  display: inline-block;
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  z-index: 10;
  background-color: #fff;
  position: absolute;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
}
.DateRangePicker_picker__directionRight {
  right: 0;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;

  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.map-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40vh;
}

.loader {
  font-size: 24px;
}

.map {
  width: 500px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}

.state {
  width: 33.33%;
  height: 33.33%;
  border: 1px solid #000;
}

.ativo {
  background-color: #00ff00;
}

.lento {
  background-color: #ffa500;
}

.inativo {
  background-color: #ff0000;
}

#svg-map text {
  fill: #fff;
  font: 12px Arial-BoldMT, sans-serif;
  cursor: pointer;
}

#svg-map a {
  text-decoration: none;
}

#svg-map a:hover {
  cursor: pointer;
  text-decoration: none;
}

#svg-map a:hover path {
  fill: #aaa !important;
}

#svg-map .circle {
  stroke-width: 1px;
  stroke: white;
}

#svg-map a:hover .circle {
  fill: #aaa !important;
  cursor: pointer;
}
.cardZone {
  display: flex;
  align-items: flex-end;
}
.card {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; /* Adiciona uma borda sólida com cor cinza */
}

.card h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  margin-bottom: 5px;
}

.badge {
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 10px; /* Adiciona espaço no topo de 10 pixels */
  color: white; /* Define a cor do texto como branco */
}

/* Estilos das cores */
.bg-success {
  background-color: #2eca8b;
  /*border: 1px solid #06b871;*/
}

.bg-yellow {
  background-color: #ffc700;
  /*border: 1px solid darkgoldenrod;*/
}

.bg-warning {
  background-color: #f17425;
  /*border: 1px solid darkorange;*/
}

.bg-danger {
  background-color: #e43f52;
  /*border: 1px solid darkred;*/
}
.bg-off {
  background-color: #7d7d7d;
  /*border: 1px solid darkred;*/
}
.mb-3 {
  margin: auto;
  margin-top: 500px;
}
.color-text {
  color: #2eca8b;
  font-weight: bold;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  color: gray;
}

