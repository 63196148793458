@media screen and (orientation: portrait) {
  .swal2-container-error,
  .swal2-container-success {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
@media screen and (orientation: landscape) {
  .swal2-container-error,
  .swal2-container-success {
    z-index: 1200;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    height: 60vh;
    border-radius: 4px;
  }
}
.swal2-container-success {
  background-color: #00d318;
  visibility: hidden;
  animation: 4s color-change;
}
.swal2-container-error {
  background-color: #ed145b;
}
.swal2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swal2-status {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  animation: 0.4s anim-lineUp ease-in;
}
.swal2-redirect {
  padding-top: 5vh;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  animation: 0.8s message ease-in;
}
.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  zoom: normal;
  display: flex;
  border-color: white;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: white;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success {
  border-color: white;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: white;
  z-index: 2;
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.875em;
  left: 0.875em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-animate-success-icon .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-animate-success-icon .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-animate-success-icon .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-animate-error-icon {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-animate-error-icon .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes message {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes color-change {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
  75% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.875em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
